import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MobilLoginComponent } from 'components/mobil/mobil-login/mobil-login.component';
import { MobilMessageComponent } from 'components/mobil/mobil-messages/mobil-messages.component';
import { FehlermeldungComponent } from 'components/mobil/fehlermeldung/fehlermeldung.component';
import { MobilSpeiseplanKundeComponent } from 'components/mobil/mobil-speiseplan-kunde/mobil-speiseplan-kunde.component';
import { MobilWarenkorbComponent } from 'components/mobil/mobil-warenkorb/mobil-warenkorb.component';
import { MobilBestellhistorieComponent } from 'components/mobil/mobil-bestellhistorie/mobil-bestellhistorie.component';
import { AuthGuardService } from 'services/guards/auth-guard-serivce';
import { MobilProfileComponent } from 'components/mobil/mobil-profile/mobil-profile.component';
import { MobilHauptmenuComponent } from 'components/mobil/mobil-hauptmenu/mobil-hauptmenu.component';
import { MobilSpeiseplanMandantComponent } from 'components/mobil/mobil-speiseplan-mandant/mobil-speiseplan-mandant.component';
import { MobilSpeiseplanOverviewComponent } from 'components/mobil/mobil-speiseplan-overview/mobil-speiseplan-overview.component';
import { EinstellungenComponent } from '../../submodul/obs.shared/componenten/einstellungen/einstellungen.component';
import {RegistrierungFormularComponent} from '../../submodul/obs.registrierung/component/registrierung-formular/registrierung-formular.component';
import {RegistrierungZusammenfassungComponent} from '../../submodul/obs.registrierung/component/registrierung-zusammenfassung/registrierung-zusammenfassung.component';
import {RegistrierungZwischenseiteComponent} from '../../submodul/obs.registrierung/component/registrierung-zwischenseite/registrierung-zwischenseite.component';
import {RegistrierungInfoComponent} from '../../submodul/obs.registrierung/component/registrierung-info/registrierung-info.component';
import {KontaktComponent} from '../../submodul/obs.shared/componenten/kontakt/kontakt.component';
import {PasswortVergessenComponent} from '../../submodul/obs.shared/componenten/passwort-vergessen/passwort-vergessen.component';
import {VerifyDialogComponent} from '../../submodul/obs.shared/dialog/verify-dialog/verify-dialog.components';
import {ProfilComponent} from '../../submodul/obs.shared/componenten/profil/profil.component';
import {MobilRechtComponent} from '../../components/mobil/mobil-rechtliches/mobil-rechtliches.component';
import {EmailVerifikationComponent} from '../../submodul/obs.shared/componenten/email-verifikation/email-verifikation.component';
import {FaqComponent} from '../../submodul/obs.shared/componenten/faq/faq.component';
import {RechnungenComponent} from '../../submodul/obs.shared/componenten/rechnungen/rechnungen.component';
import {HomeComponent} from '../../submodul/obs.shared/componenten/home/home.component';
import {StartupNavigationGuard} from '../../services/guards/startup-navigation.guard';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [StartupNavigationGuard]},
  {path: 'home', component: HomeComponent},
  {path: 'menu', component: MobilHauptmenuComponent},
  {path: 'login', component: MobilProfileComponent},
  {path: 'passwort-vergessen', component: PasswortVergessenComponent},
  {path: 'verify-email/:id', component: VerifyDialogComponent},
  {path: 'speiseplan-mandant/:id', component: MobilSpeiseplanMandantComponent},
  {path: 'speiseplan-kunde', component: MobilSpeiseplanKundeComponent},
  {path: 'warenkorb', component: MobilWarenkorbComponent},
  {path: 'rechtliches', component: MobilRechtComponent},
  {path: 'rechtliches/:id', component: MobilRechtComponent},
  {path: 'fehler', component: FehlermeldungComponent},
  {path: 'kontakt', component: KontaktComponent},
  {path: 'bestellhistorie', component: MobilBestellhistorieComponent, canActivate: [AuthGuardService]},
  {path: 'bestellhistorie/:zeitraum', component: MobilBestellhistorieComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: MobilProfileComponent},
  {path: 'profil/:id', component: ProfilComponent},
  {path: 'benachrichtigungen', component: MobilMessageComponent},
  {path: 'speiseplan-overview', component: MobilSpeiseplanOverviewComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'einstellungen', component: EinstellungenComponent},
  {path: 'register-zwischenseite', component: RegistrierungZwischenseiteComponent},
  {path: 'register-info/:id', component: RegistrierungInfoComponent},
  {path: 'register-form/:id', component: RegistrierungFormularComponent},
  {path: 'register-abschluss/:id', component: RegistrierungZusammenfassungComponent},
  {path: 'rechnungen', component: RechnungenComponent},

  // redirect pfade
  {path: 'register', redirectTo: 'register-zwischenseite', pathMatch: 'full'},
  {path: 'contact', redirectTo: 'kontakt', pathMatch: 'full'},
  {path: 'reset_password/:id', component: EmailVerifikationComponent, pathMatch: 'full' },
  {path: 'verify_email/:id', component: EmailVerifikationComponent, pathMatch: 'full' },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {enableTracing: false, useHash:true})
  ],
  declarations: []
})
export class RoutingModule { }
