<div id="mobil-hauptmenu" class="app-content">
  <div id="mobil-hauptmenu-header">
    <mat-card>
      <img [src]="logo" (error)="onImageError()">
    </mat-card>
  </div>
  <div id="mobilHauptmenuContent">
    <div id="mobilHauptmenuScrollContent">
      <div id="mobilHauptmenuPlatzhalter"></div>
      <div class="mobile-menue-container" *ngIf="mandant && (length > 0 || home_config())">
        <mat-card class="mobilHauptmenuHeading">
          <strong *ngIf="mandant">Öffentlicher Bereich</strong>
        </mat-card>
        <mat-card class="mobile-menue-link" routerLinkActive="_active" *ngIf="home_config()"
                  (click)="app_state_service.check_page_menu(); home_link()" [routerLinkActiveOptions]="{exact:true}">
          <mat-icon aria-label="Speiseplan">home</mat-icon>
          <span>Home</span>
        </mat-card>
        <ng-container *ngIf="mandant && !kunde && mandant.navigation_root.menu">
          <mat-card class="mobile-menue-link" *ngFor="let speiseplan_id of mandant.speisePlanIds | keyvalue"
                    [routerLink]="['/speiseplan-mandant', speiseplan_id.value.splanId]" routerLinkActive="_active"
                    (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">
            <mat-icon aria-label="Öffentlicher Speiseplan">restaurant</mat-icon>
            <span>{{ speiseplan_id.value.bezeichnung }}</span>
          </mat-card>
        </ng-container>
      </div>

      <div class="mobile-menue-container" *ngIf="kunde">
        <mat-card class="mobilHauptmenuHeading"><strong>Mein Bestellprozess</strong></mat-card>
        <mat-card class="mobile-menue-link" routerLink="/speiseplan-kunde" routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">
          <mat-icon aria-label="Speiseplan">restaurant</mat-icon>
          <span>Speiseplan</span>
        </mat-card>
        <mat-card class="mobile-menue-link" routerLink="/warenkorb" disabled routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">
          <mat-icon aria-label="Warenkorb">shopping_cart</mat-icon>
          <span>Warenkorb</span>
        </mat-card>
        <mat-card class="mobile-menue-link" routerLink="/bestellhistorie" disabled routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">
          <mat-icon aria-label="Bestellhistorie">date_range</mat-icon>
          <span>Bestellhistorie</span>
        </mat-card>
      </div>

      <div class="mobile-menue-container">
        <mat-card class="mobilHauptmenuHeading">
          <strong>Persönlicher Bereich</strong>
        </mat-card>
        <mat-card class="mobile-menue-link" routerLink="/login" disabled routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()">
          <mat-icon aria-label="Profilübersicht">login</mat-icon>
          <span>Anmelden</span>
        </mat-card>
        <ng-container
          *ngIf="mandant && (mandant.darstellungRegistrierung == '1' || mandant.darstellungRegistrierung == '2') && mandant.mailerAktiv && !kunde">
          <mat-card class="mobile-menue-link" routerLinkActive="_active"
                    (click)="registrationService.link_to_registration(mandant)"
                    [routerLinkActiveOptions]="{exact:true}">
            <mat-icon aria-label="Registrierung">app_registration</mat-icon>
            <span>Registrieren</span>
          </mat-card>
        </ng-container>
        <mat-card *ngIf="profilAnzahl > 0" class="mobile-menue-link" routerLink="/profile" disabled routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()">
          <mat-icon aria-label="Benutzerüberischt & -informationen">people</mat-icon>
          <span>Benutzerübersicht & -informationen</span>
        </mat-card>
<!--        <mat-card *ngIf="kunde" class="mobile-menue-link messages" routerLink="/rechnungen" routerLinkActive="_active"-->
<!--                  (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">-->
<!--          <mat-icon aria-label="Mitteilungen">receipt_long</mat-icon>-->
<!--          <span>Rechnungen</span>-->
<!--        </mat-card>-->
        <mat-card class="mobile-menue-link" routerLink="/einstellungen" routerLinkActive="_active"
                  (click)="app_state_service.check_page_menu()" [routerLinkActiveOptions]="{exact:true}">
          <mat-icon aria-label="Einstellungen">settings</mat-icon>
          <span>Einstellungen</span>
        </mat-card>

      </div>

      <div class="mobile-menue-container">
        <mat-card class="mobilHauptmenuHeading">
          <strong>Hilfe</strong>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="flexLink.flexLinkTesten('kontakt')" (click)="app_state_service.check_page_menu(); flexLink.flexLinkPruefen('kontakt')">
          <mat-icon aria-label="Kontakt">perm_phone_msg</mat-icon>
          <span>Kontakt</span>
        </mat-card>
        <mat-card class="mobile-menue-link" (click)="app_state_service.check_page_menu(); faqParams('installation')">
          <mat-icon aria-label="Installation">install_mobile</mat-icon>
          <span>Installationsanleitung</span>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="enviroment._type == 'pwa' && guthabenSopro.mandantenCheckup(mandantId) && kunde"
                  (click)="faqParams('guthaben')">
          <mat-icon aria-label="Aufladen">savings</mat-icon>
          <span>Guthaben aufladen</span>
        </mat-card>
        <mat-card class="mobile-menue-link" (click)="flexLink.flexLinkPruefenFAQMobil('faq')">
          <mat-icon aria-label="FAQ">help</mat-icon>
          <span>FAQ</span>
        </mat-card>
      </div>

      <div class="mobile-menue-container" *ngIf="mandant">
        <mat-card class="mobilHauptmenuHeading">
          <strong>Rechtliches</strong>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="flexLink.flexLinkTesten('agb')" (click)="flexLink.flexLinkPruefen('agb')">
          <mat-icon aria-label="AGB">fact_check</mat-icon>
          <span>AGB</span>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="flexLink.flexLinkTesten('datenschutz')" (click)="flexLink.flexLinkPruefen('datenschutz')">
          <mat-icon aria-label="Datenschutz">security</mat-icon>
          <span>Datenschutz</span>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="flexLink.flexLinkTesten('widerruf')" (click)="flexLink.flexLinkPruefen('widerruf')">
          <mat-icon aria-label="Widerruf">pan_tool</mat-icon>
          <span>Widerruf</span>
        </mat-card>
        <mat-card class="mobile-menue-link" *ngIf="flexLink.flexLinkTesten('impressum')" (click)="flexLink.flexLinkPruefen('impressum')">
          <mat-icon aria-label="Impressum">gavel</mat-icon>
          <span>Impressum</span>
        </mat-card>
        <mat-card class="mobile-menue-link"
                  *ngIf="mandant.mailerAktiv && flexLink.flexLinkTesten('kuendigung')"
                  (click)="flexLink.flexLinkPruefen('kuendigung')">
          <mat-icon aria-label="Kündigung">playlist_remove</mat-icon>
          <span>Kündigung</span>
        </mat-card>
      </div>

      <div class="mobile-menue-container" *ngIf="mandant">
        <mat-card class="mobilHauptmenuHeading">
          <strong>Über die App</strong>
        </mat-card>
        <mat-card class="mobile-menue-link mobileMenueInfo">
          <div>
            <strong>Status der App</strong>
            <div *ngIf="serviceWorkerStatus" class="statusServiceWorkerGut statusServiceWorker">
              <mat-icon>done</mat-icon>
              <span>Hintergrunddienste sind aktiv</span>
            </div>
            <div *ngIf="!serviceWorkerStatus" class="statusServiceWorkerSchlecht statusServiceWorker">
              <mat-icon>close</mat-icon>
              <span>Hintergrunddienste sind nicht aktiv</span>
            </div>

            <div *ngIf="!serviceWorkerUpdate" class="statusServiceWorkerGut statusServiceWorker">
              <mat-icon>done</mat-icon>
              <span>Anwendung ist aktuell</span>
            </div>
            <div *ngIf="serviceWorkerUpdate" class="statusServiceWorkerSchlecht statusServiceWorker">
              <mat-icon>close</mat-icon>
              <span>Ihre Anwendung ist nicht mehr aktuell. Bitte aktualisieren Sie die Anwendung.</span>
            </div>
          </div>
          <div class="poweredBy">
            <strong>Version</strong>
            <span gitVersion>2023.38.2</span>
          </div>
        </mat-card>
        <mat-card>
          <div id="logo">
            <span>powered by</span>
            <a target="_blank" href="https://www.rcs.de">
              <img src="./assets/icons/company.webp">
            </a>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
